import React, { useContext } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { useRef, useState} from "react";
import { themeContext } from "../../Context";

const Contact = () => {
    const form = useRef();

    const [done, setDone] = useState(false);

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_m4qimqq', 'template_ivrnrrx', form.current, 'ekRWeMoSrplw4Tv90')
        .then((result) => {
            console.log(result.text);
            setDone(true);
        }, (error) => {
            console.log(error.text);
        });
    };
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

  return (
    <div className="contact-form">
        <div className="c-left">
            <span style={{color: darkMode? "white": ""}}>Get in Touch </span>
            <br/>
            <span>Contact Me</span>
        </div>

        <div className="c-right">
            <form ref={form} onSubmit={sendEmail}>
                <input type="text" name="user_name" className="user" placeholder="Name" />
                <input type="email" name="user_email" className="user" placeholder="Email" />
                <textarea name="message" className="user" placeholder="Message" />
                <input type="submit" value="Send" className="btn s-btn" />
                <span>{done && "Thanks for contacting me!"}</span>
            </form>
        </div>
    </div>
  )
}

export default Contact;
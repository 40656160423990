import React, { useContext } from "react";
import "./Works.css";
import Upwork from "../../img/Upwork.png";
import online from "../../img/online.png";
import dmsi from "../../img/dmsi.png";
import yamaha from "../../img/yamaha.png";
import react from "../../img/react.png";
import { themeContext } from "../../Context";
import {motion} from "framer-motion";

const Works = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="works">
        <div className="myServices">
          <span style={{color: darkMode? "white": ""}}>Worked for All of These</span>
          <span>Brands & Clients</span>
          <button className="btn s-btn">Hire Me</button>
        </div>

        <div className="w-right">
          <motion.div 
          initial={{rotate: 45}}
          whileInView={{rotate: 0}}
          viewport={{margin: "-40px"}}
          transition={{duration: 3.5, type: "spring"}}
          className="w-mainCircle">
            <div className="w-secCircle">
              <img src={Upwork} alt="" />
            </div>
            <div className="w-secCircle">
              <img src={online} alt="" />
            </div>
            <div className="w-secCircle">
              <img src={dmsi} alt="" />
            </div>
            <div className="w-secCircle">
              <img src={yamaha} alt="" />
            </div>
            <div className="w-secCircle">
              <img src={react} alt="" />
            </div>
          </motion.div>
          <div className="w-backCircle blueCircle"></div> 
          <div className="w-backCircle yellowCircle"></div> 
        </div>
    </div>
  )
}

export default Works;
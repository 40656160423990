import React, { useContext } from "react";
import "./Toggle.css";
import {FaRegSun, FaRegMoon} from "react-icons/fa";
import { themeContext } from "../../Context";

const Toggle = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    const handleClick = () => {
       theme.dispatch({type: "toggle"}) 
    }

  return (
    <div className="toggle" onClick={handleClick}>
         <FaRegMoon />
        <FaRegSun />
        <div className="t-btn"
        style={darkMode? {left: "2px"} : {right: "2px"}}
        >

        </div>
    </div>
  )
}

export default Toggle;
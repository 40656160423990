import React, { useContext } from "react";
import "./Testimonial.css";
import {Swiper, SwiperSlide} from "swiper/react";
import Profile1 from "../../img/profile1.jpg";
import Profile2 from "../../img/profile2.jpg";
import Profile3 from "../../img/profile3.jpg";
import Profile4 from "../../img/profile4.jpg";
import {Pagination} from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import { themeContext } from "../../Context";

const Testimonial = () => {
  const clients = [
    {
      img: Profile1,
      review: "Working with Marcos has been a game-changer for our online presence. Their web development skills are top-notch, and they've transformed our website into a user-friendly, visually appealing platform. Highly recommended!"
    },

    {
      img: Profile2,
      review: "Marcos is a web development wizard! They took our vague ideas and turned them into a sleek and functional website that exceeded our expectations. Their attention to detail and commitment to delivering on time is truly impressive"
    },

    {
      img: Profile3,
      review: "I couldn't be happier with the website Marcos created for my business. It's not only beautiful but also highly functional. They were responsive to my needs and patiently walked me through everything. I'm grateful for their expertise"
    },

    {
      img: Profile4,
      review: "If you're looking for a web developer who can turn your vision into reality, Marcos is the one to hire. They are a talented developer who combines creativity with technical prowess, resulting in websites that stand out in the digital world."
    }
  ]
  
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div className="t-wrapper" id="Testimonials">
      <div className="t-heading">
        <span>Clients ALWAYS gets </span>
        <span>Exceptional Works </span>
        <span>from Me..</span>
      </div>

      <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{clickable: true}}>

        {clients.map((client, index) => {
            return (
              <SwiperSlide key={index}>
              <div className="testimonial">
                <img src={client.img} alt="" />
                <span style={{color: darkMode? "white": ""}}>{client.review}</span>
                </div>
              </SwiperSlide>
            )
        })}

      </Swiper>
    </div>
  )
}

export default Testimonial;
import React from "react";
import "./Footer.css";
import Wave from "../../img/Wave.png";
import {FaGithub, FaLinkedin, FaFacebookSquare} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer">
        <img src={Wave} alt="" style={{width: '100%', height: '50%'}} />
        <div className="f-content">
            <span>marrionemarcuzsean@gmail.com</span>

            <ul className="f-icons">
                <li className="f-iconss"><a href="https://emharock.github.io" target="_blank" rel="noopener noreferrer">
                <FaGithub  style={{color: "white", fontSize:"4em"}}/>
                </a> </li>
                <li className="f-iconss"><a href="https://www.linkedin.com/in/mark-anthony-dumlao-406360278/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin style={{color: "white", fontSize:"4em"}}/>
                </a></li>
                <li className="f-iconss"><a href="https://www.facebook.com/marcanthony.dumlao/" target="_blank" rel="noopener noreferrer">
                <FaFacebookSquare style={{color: "white", fontSize:"4em"}} />
                </a></li>
            </ul> 

            
        </div>
    </div>
    
  )
}

export default Footer;
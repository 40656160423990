import React, { useContext } from "react";
import "./Intro.css";
import {FaGithub, FaLinkedin, FaFacebookSquare} from "react-icons/fa";
import marcos from "../../img/marcos.png";
import web from "../../img/web.png";
import vector from "../../img/vector.png";
import cube from "../../img/cube.png";
import { themeContext } from "../../Context";
import {motion} from "framer-motion";


const Intro = () => {

    const transition = {duration: 2, type: "spring"};

    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;
    return (
        <div className="intro">
            <div className="i-left">
                <div className="i-name">
                <span style={{color: darkMode? "white": ""}}>Hey! I am</span>
                <span>Mark Anthony Dumlao</span>
                <span>Web Developer | Career Shifter</span>
                </div>
            <button className="btn i-btn">Hire Me</button> 
            <ul className="i-icons">
                <li className="i-iconss"><a href="https://emharock.github.io" target="_blank" rel="noopener noreferrer">
                <FaGithub  style={{color: darkMode? "white" : "black", fontSize:"2em"}}/>
                </a> </li>
                <li className="i-iconss"><a href="https://www.linkedin.com/in/mark-anthony-dumlao-406360278/" target="_blank" rel="noopener noreferrer">
                <FaLinkedin style={{color: darkMode? "white" : "#0a66c2", fontSize:"2em"}}/>
                </a></li>
                <li className="i-iconss"><a href="https://www.facebook.com/marcanthony.dumlao/" target="_blank" rel="noopener noreferrer">
                <FaFacebookSquare style={{color: darkMode? "white" : "#0879e9", fontSize:"2em"}} />
                </a></li>
            </ul>   
            </div>
            <div className="i-right">
            <motion.img 
            initial={{top: "-28%", right: "-2%"}}
            whileInView={{top: "-18%", right: "-2%"}}
            transition={transition}
            src={vector} alt=""/>
            <motion.img 
             initial={{top: "-25%", right: "-10%"}}
            whileInView={{top: "-35%", right: "-10%"}}
            transition={transition}
            src={marcos} alt=""/>
            <motion.img 
            initial={{top: "20%", right: "38%"}}
            whileInView={{top: "20%", right: "28%"}}
            transition={transition}
            src={web} alt=""/>            
            <motion.img 
            initial={{top: "13%", right: "-20%"}}
            whileInView={{top: "13%", right: "-10%"}}
            transition={transition}
            src={cube} alt=""/>
            </div>
            </div>
    )
}
export default Intro;
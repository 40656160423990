import React, { useContext } from "react";
import "./Services.css";
import Ps from "../../img/Ps.png";
import UiUx from "../../img/UiUx.png";
import code from "../../img/code.png";
import Card from "../Card/Card";
import cv from "./cv.pdf";
import { themeContext } from "../../Context";
import {motion} from "framer-motion";

const Services = () => {
  const transition = {duration: 2, type: "spring"};
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div className="services" id="Services">
      <div className="myServices">
          <span style={{color: darkMode? "white": ""}}>Service</span>
          <span>Spectrum</span>
          <a href= {cv} download>
          <button className="btn s-btn">Download CV</button>
          </a>
      </div>

      <div className="cards">

        <motion.div 
            initial={{left: "54rem"}}
            whileInView={{left: "44rem"}}
            transition={transition}
        style={{left: "44rem"}}>
          <Card
          image = {Ps}
          heading = {"Design"}
          detail = {"Adobe Photoshop"}
          />
        </motion.div>

        <motion.div 
            initial={{top: "8rem", left: "16rem"}}
            whileInView={{top: "8rem", left:"26rem"}}
            transition={transition}
        style={{top: "8rem", left: "26rem"}}>
          <Card
          image = {code}
          heading = {"Developer"}
          detail = {"HTML, CSS, JavaScript, React"}
          />
        </motion.div>

        <motion.div 
            initial={{top: "29rem", right: "42rem"}}
            whileInView={{top: "19rem", right: "42rem"}}
            transition={transition}
        style={{top: "19rem", left: "42rem"}}>
          <Card
          image = {UiUx}
          heading = {"UI/UX"}
          detail = {"Visual elements, layout, and design of a digital product."}
          />
        </motion.div>
        
      </div>
    </div>

  )
}

export default Services;